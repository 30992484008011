<div class="container-fluid p-0">
    <app-header></app-header>
    <app-about></app-about>
    <app-masthead></app-masthead>
    <app-education></app-education>
    <app-portfolio></app-portfolio>
    <app-about-project></app-about-project>
    <app-contact></app-contact>
    <app-footer></app-footer>
    <app-footer-warning></app-footer-warning>
</div>
