<section class="resume-section" id="projects">
    <div class="resume-section-content">
        <h2 class="mb-3">Projetos</h2>
        <div class="subheading mb-3">Projetos pessoais</div>
        <div class="container mb-5">
            <mat-expansion-panel
                class="m-4" 
                *ngFor="let project of projects; index as i"
            >
                <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                    <mat-panel-title class="masthead-subheading d-flex justify-content-center">
                        <span class="text-center subheading m-2">{{ project.title }}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                
                <div class="flex-grow-1 m-1 py-2">
                    <p class="lead text-justified">{{ project.description }}</p>                    
                    <a href="{{ project.url }}" target="_blank">
                        <button class="lead btn btn-light table thead th border rounded">
                            <i class="fa-solid fa-code"></i>&nbsp;
                            {{ project.textButton }}
                        </button>
                    </a>
                </div>
            </mat-expansion-panel>
        </div>

        <h3 class="mb-0">Veja o código fonte</h3>
        <div class="text-center mt-4">
            <a 
                *ngFor="let repository of repositories; index as i"
                class="btn btn-lg btn-outline-secondary m-2"
                href="{{ repository.url }}"
                target="_blank"
            >
                <i class="fab fa-fw fa-github mr-2"></i> {{ repository.description }}
            </a>
        </div>                
    </div>
</section>
<hr class="m-0" />