<section id="footer" class="text-center">
    <div class="flex-grow-1">        
        <div class="subheading m-2">Sobre esta página</div>
        <p>
            Página criada em Angular 11 consumindo duas APIs .NET Core 3.1.
        </p>
    </div>
</section>
<div class="py-4 text-center">
    <div class="container">
        <small>
            Licença MIT <span class="copyleft">&copy;</span> Lucas Vieira Vicente - {{ year }}
        </small>
    </div>
</div>