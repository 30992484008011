<section class="resume-section" id="experience">
    <div class="resume-section-content">
        <h2 class="mb-5">Experiência</h2>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <h3 class="mb-0">Software Engineer</h3>
                <div class="subheading mb-3">BTG Pactual</div>
                <p class="lead"> 
                    Atuando na engenharia de software do Primeiro Acesso/Onboarding e Abertura de contas do BTG Empresas, utilizando diversas tecnologias como .NET, React, serviços AWS além de diversos design patterns como DDD, TDD, CQRS, orientação a eventos, Monad e etc...
                </p>
            </div>
            <div class="flex-shrink-0"><span class="subheading date">Agosto 2022 - Atualmente</span></div>
        </div>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <h3 class="mb-0">Software Engineer</h3>
                <div class="subheading mb-3">Iteris Consultoria e Software</div>
                <p class="lead">
                    Atuando com desenvolvimento de sistemas em diversos clientes (REDE, SGS, Easynvest/NuInvest) utilizando diversas tecnologias com C#, e frameworks JS (Angular e React), sistemas cloud (Azure e AWS) e diversos design patterns
                </p>
            </div>
            <div class="flex-shrink-0"><span class="subheading date">Setembro 2020 - Julho 2022</span></div>
        </div>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <h3 class="mb-0">Software Engineer Intern</h3>
                <div class="subheading mb-3">So+ma Vantagens</div>
                <p class="lead">
                    Estagiário em engenheiria de software criando novas funções e prestando manutenção com diversas tecnologias como .NET Core, Microsoft Azure, SQL Server e etc...
                </p>
            </div>
            <div class="flex-shrink-0"><span class="subheading date">Dezembro 2019 - Agosto 2020</span></div>
        </div>
    </div>
</section>
<hr class="m-0" />