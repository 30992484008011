<section class="resume-section" id="skills">
  <div class="resume-section-content">
    <h2 class="mb-5">Conhecimento técnico</h2>
    <div class="subheading mb-3">Experiências técnicas como desenvolvedor</div>
          
    <div class="container" *ngIf="stacks; else loading">
        <app-stack-panel
          *ngFor="let stack of stacks; index as i" 
          [stack]="stack">
        </app-stack-panel>
    </div>

    <ng-template class="row" #loading>
      <mat-spinner class="mx-auto mt-5"></mat-spinner>
    </ng-template>
  </div>
</section>
<hr class="m-0" />