<section class="resume-section" id="about">
    <div class="resume-section-content">
        <h1 class="mb-0">Lucas Vieira Vicente</h1>
        <div class="subheading mb-4">
            <i class="fa-solid fa-map-location-dot"></i>&nbsp;<a href="https://goo.gl/maps/1eTjhJJapNkz1fE99" target="_blank">Mauá - São Paulo</a><br>
            <i class="fa-solid fa-mobile"></i> +55 (11) 98377-3017<br>
            <i class="fa-solid fa-at"></i>&nbsp;<a href="mailto:lucasvieiravicente1@gmail.com">lucasvieiravicente1@gmail.com</a>
        </div>
        <p class="lead mb-2"> 
            Iniciei minha "aventura" a {{ getExperienceTime() }} anos... Estudando algoritmos e lógica, em sequência ingressei na FATEC para estudar Informática para Negócios, e mantive meu foco e interesse na área de engenharia de software. Então a {{ getProfessionalTime() }} anos comecei minha jornada no mercado de trabalho como estagiário em engenharia de software, atuando com desenvolvimento web, atendendo as necessidades do projeto e do time conforme a demanda, tanto com back-end e front-end.
        </p>
        <p class="lead mb-4">
            Durante essa trajetória complexa, desafiadora e cheia de aprendizado colaborei em projetos das mais diversas áreas como: financeiro, investimentos, inspeção e certificação; encarregando-se do processo completo desde o entendimento das necessidades do cliente, negociação das entregas a serem desenvolvidas e na construção do projeto. Além de que, apesar de não atuar oficialmente em cargos de liderança, atuei nas necessidades diretas dos times o qual participei, auxiliando como um pilar para o time em qualidade técnica e orientações profissionais, a exemplo de pair programming, code review, orientações técnicas e etc...
        </p>
        <p class="lead mb-4">
            
        </p>
        <div class="social-icons text-center">
            <a *ngFor="let socialMedia of socialMedias index as i"
                href="{{ socialMedia.url }}" target="_blank"
                class="social-icon"
            >
                <i class="{{ socialMedia.icon }}"></i>
            </a>
        </div>

        <div class="text-center mt-4">
            <p class="lead">
                Caso ainda necessite do meu currículo, você pode baixa-lo, porém recomendo a leitura da página!
            </p>
            <a class="btn btn-lg btn-outline-secondary" href="assets/files/curriculoPdf.pdf" target="_blank">
                <i class="fas fa-download mr-2"></i> Currículo
            </a>
        </div>
    </div>
</section>
<hr class="m-0" />