<div
*ngIf="!hasCookie"
@fadeSlideInOut 
class="cookiesWarningDiv">
    <div id="cookieContent">
        <div id="cookieDescription">
            <p>
                Esta página utiliza cookies para controle do acesso ao site
                <br />
                Caso concorde com o uso dos mesmos só {{ message }} no monstrinho do cookie.
                <br />
            </p>
        </div>
        <div id="cookieImage">
            <img src="assets/images/cookieImage.png" alt="Cookie Image" title="Cookie Image" (click)="setCookie()"/>
        </div>
    </div>
</div>