<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="sideNav">
    <a class="navbar-brand js-scroll-trigger" (click)="scroll('about')">
        <span class="d-block d-lg-none">Lucas Vieira Vicente</span>
        <span class="d-none d-lg-block">
            <img class="img-fluid img-profile rounded-circle mx-auto mb-2 cursor" src="assets/images/myPhoto3.jpg" alt="Foto do Lucas" />
        </span>
    </a>

    <button 
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link js-scroll-trigger" (click)="scroll('about')"><span class="cursor">Sobre</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link js-scroll-trigger" (click)="scroll('experience')"><span class="cursor">Experiência</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link js-scroll-trigger" (click)="scroll('education')"><span class="cursor">Educação</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link js-scroll-trigger" (click)="scroll('skills')"><span class="cursor">Conhecimento</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link js-scroll-trigger" (click)="scroll('projects')"><span class="cursor">Projetos</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link js-scroll-trigger" (click)="scroll('contact')"><span class="cursor">Contato</span></a>
            </li>
        </ul>
    </div>
</nav>