<section class="resume-section" id="education">
  <div class="resume-section-content">
      <h2 class="mb-5">Educação</h2>
      <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
          <div class="flex-grow-1">
              <h3 class="mb-0">FATEC - Mauá</h3>
              <div class="subheading mb-3">Tecnologia em Informática para Negócios</div>
              <div class="lead">Mauá - São Paulo</div>
              <p class="lead">GPA: 8.22</p>
          </div>
          <div class="flex-shrink-0"><span class="subheading date">Agosto 2017 - Julho 2022</span></div>
      </div>
  </div>
</section>
<hr class="m-0" />