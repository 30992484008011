<mat-accordion class="page-section m-1" id="portfolio">
  <div class="container"> 
    <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title class="masthead-subheading d-flex justify-content-center">
            <span class="text-center subheading mb-1 mt-1">{{ this.stack.stackName }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
      <app-knowledge-card></app-knowledge-card>
    </mat-expansion-panel>
  </div>

</mat-accordion>